import * as React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import InputField from '../components/form/InputField'
import StillHaveQuestions from '../components/StillHaveQuestions'
import FormWrapper from '../components/form/FormWrapper'
import useFormState from '../components/form/hooks/useFormState'
import PropTypes from 'prop-types'
import isEmail from '../components/form/validators/isEmail'
import isPhone from '../components/form/validators/isPhone'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'
import { BellAlertIcon, BellSlashIcon } from '@heroicons/react/24/solid'

const CheckboxOptions = ({ option, form, name, onChange }) => {
  const onChangeHandler = e => {
    if (onChange) {
      onChange(e, name)
    }
  }
  return (
    <label>
      <input
        type="checkbox"
        className="peer m-2 h-4 w-4 translate-y-[.25rem] border border-black accent-gray-600 sm:h-5 sm:w-5"
        value={option}
        onChange={onChangeHandler}
        checked={form[name]}
      />
      <p className=" inline-block text-gray-800 hover:text-black peer-checked:text-black ">
        {option}
        {form[name]}
      </p>
    </label>
  )
}
CheckboxOptions.propTypes = {
  option: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

const TheForm = ({ unsubscribe, setUnsubscribe }) => {
  // eslint-disable-next-line no-unused-vars
  const [form, _setForm, updateForm] = useFormState({
    fName: '',
    lName: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    newsletter: '',
    // unsubscribe: null,
    // pcgsCoinNewsletter: false,
    // instagram: false,
    // subscribeEmail: false,
    // facebook: false,
    // google: false,
    // hotelInRoomMagazine: false,
    // elVenezolano: false,
    // miamiBeachMagazine: false,
    // miamiHerald: false,
    // referredByFriend: false,
    // other: false,
    expandRequest: true,
  })
  return (
    <FormWrapper
      form={form}
      updateForm={updateForm}
      name={unsubscribe === true ? 'Catalog Unsubscribe' : 'Catalog Request Form'}
      action={'/thank-you-catalog/'}
    >
      {unsubscribe === true ? (
        <h2 className="text-xl font-bold text-gray-600">
          <BellSlashIcon className="inline-block size-6 mr-2" />
          Unsubscribe From Monthly Printed Catalog
        </h2>
      ) : (
        <h2 className="text-xl font-bold text-green-700">
          <BellAlertIcon className="inline-block size-6 mr-2" />
          Subscribe To Monthly Printed Catalog
        </h2>
      )}
      <div className={'grid grid-cols-4 gap-x-3'}>
        {/* <p className="col-span-4 xl:text-lg">
                  Fill out this form to receive $200 off your first purchase and our free 76 page
                  monthly catalog To receive our Digital Catalog enter your e-mail below: <br />
                  To receive our Physical Catalog enter your address:
                  <span className="text-sm">
                    <br />
                    *US Customers Only. International Customers receive digital version.
                  </span>
                </p> */}
        <InputField
          name={'fName'}
          label={'First Name'}
          form={form}
          onChange={updateForm}
          className={'col-span-4 lg:col-span-2'}
          required={true}
        />
        <InputField
          name={'lName'}
          label={'Last Name'}
          form={form}
          onChange={updateForm}
          className={'col-span-4 lg:col-span-2'}
          required={true}
        />
        <InputField
          name={'phone'}
          label={'Telephone'}
          form={form}
          validator={isPhone}
          onChange={updateForm}
          className={'col-span-4 lg:col-span-2'}
          required={true}
        />
        <InputField
          name={'email'}
          label={'E-Mail'}
          form={form}
          onChange={updateForm}
          validator={isEmail}
          className={'col-span-4 lg:col-span-2'}
          required={true}
        />

        {/* Printed Catalog Request */}
        <div className={'col-span-4 grid w-full origin-top grid-cols-4 gap-x-3  duration-200 '}>
          <InputField
            name={'address1'}
            label={'Address'}
            form={form}
            onChange={updateForm}
            className={'col-span-3 lg:col-span-3'}
            required={true}
          />
          <InputField
            name={'address2'}
            label={'Suite'}
            form={form}
            onChange={updateForm}
            className={'col-span-1 lg:col-span-1'}
          />
          <InputField
            name={'city'}
            label={'City'}
            form={form}
            onChange={updateForm}
            className={'col-span-4'}
            required={true}
          />
          <InputField
            name={'state'}
            label={'State'}
            form={form}
            onChange={updateForm}
            className={'col-span-3 lg:col-span-3'}
            required={true}
          />
          <InputField
            name={'zip'}
            label={'Zip'}
            form={form}
            onChange={updateForm}
            className={'col-span-1 lg:col-span-1'}
            required={true}
          />
        </div>
        {/* <div className="col-span-4 grid w-full grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-y-2 z-10">
                <h2 className="col-span-1 sm:col-span-2 inline-block text-lg uppercase">
                  How did you hear about us?
                </h2>
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'pcgsCoinNewsletter'}
                  option={'PCGS Coin Newsletter'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'instagram'}
                  option={'Instagram'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'subscribeEmail'}
                  option={'Email'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'facebook'}
                  option={'Facebook'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'google'}
                  option={'Google'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'hotelInRoomMagazine'}
                  option={'Hotel In-Room Magazine'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'elVenezolano'}
                  option={'El Venezolano'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'miamiBeachMagazine'}
                  option={'Miami Beach Magazine'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'miamiHerald'}
                  option={'Miami Herald'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'referredByFriend'}
                  option={'Referred By Friend'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'other'}
                  option={'Other'}
                />
              </div> */}
        <button
          onClick={() => setUnsubscribe(null)}
          className="col-span-2 place-self-start my-7 w-full text-gray-400 hover:text-gray-700 py-3 px-5 font-bold border-2 border-gray-400 hover:border-gray-700 md:mx-0"
        >
          BACK
        </button>
        <button className="col-span-2 place-self-end my-7 w-full bg-gray-600 py-3 px-5 font-bold text-white hover:bg-gray-700 md:mx-0 border-2 border-gray-600">
          SEND
        </button>
      </div>
    </FormWrapper>
  )
}
TheForm.propTypes = {
  unsubscribe: PropTypes.bool,
  setUnsubscribe: PropTypes.func,
}

const CatalogSubscriptionUpdate = () => {
  const { catalog } = useStaticQuery(graphql`
    query catalogCoverRequest {
      catalog: strapiCatalog {
        cover {
          gatsbyImageDataMock
        }
      }
    }
  `)
  processGatsbyImageDataMock(catalog.cover)
  const [unsubscribe, setUnsubscribe] = React.useState(null)

  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo
        description="Sign up to receive a printed copy of our FREE monthly catalog. Get special discounts and more in your mailbox."
        title="Gray & Sons Catalog Request"
        noindex={true}
      />
      <section className="mx-auto flex w-10/12 max-w-[1366px] flex-col flex-wrap py-10 md:flex-row">
        <h1 className="w-full text-center text-lg lg:text-3xl font-bold text-gray-600 uppercase">
          Update your printed catalog mailing preferences
        </h1>
        <p className="mx-auto my-5 w-full max-w-[800px]">
          Our monthly, 76-page printed catalog is mailed to US clients only. International client
          will receive the digital edition of our catalog by email.
        </p>
        <div className="mx-auto flex w-full items-start justify-center lg:w-1/2">
          <GatsbyImage
            alt={'Rolex, Patek Philippe, GIA Diamond Ring and Studs'}
            image={getImage(catalog.cover)}
          />
        </div>

        <div className=" mx-auto flex w-full flex-col items-start justify-start lg:w-1/2">
          {unsubscribe === null ? (
            <div>
              <h2 className="text-xl font-bold mb-4">What would you like to do?</h2>
              <div className="mx-auto flex flex-col w-full items-start justify-center gap-3 md:gap-10 ">
                <div className="lg:w-1/2">
                  <button
                    className="aspect-video w-full max-w-[20rem] bg-green-700 text-white hover:bg-green-800 flex justify-center items-center gap-3 md:text-xl font-bold p-4"
                    onClick={() => setUnsubscribe(false)}
                  >
                    <BellAlertIcon className="h-8 w-8" /> SUBSCRIBE
                  </button>

                  <p className="my-4">
                    You will recieve our printed monthly catalog with New Arrivals and Exclusive
                    Offers.
                  </p>
                </div>
                <div className="lg:w-1/2">
                  <button
                    className=" w-full max-w-[20rem] bg-gray-400 text-white hover:bg-gray-600 flex justify-center items-center gap-3 md:text-xl font-bold p-4"
                    onClick={() => setUnsubscribe(true)}
                  >
                    <BellSlashIcon className="inline-block h-8 w-8" /> UNSUBSCRIBE
                  </button>
                  <p className="my-4">
                    You will <b className="italic">STOP</b> receiving our printed monthly catalog
                    with New Arrivals and Exclusive Offers.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <TheForm setUnsubscribe={setUnsubscribe} unsubscribe={unsubscribe} />
          )}
        </div>
      </section>
      <hr className="w-10/12 max-w-[1000px] mx-auto" />
      <StillHaveQuestions />
      <Map />
    </Layout>
  )
}

export default CatalogSubscriptionUpdate
